import React, { useState } from 'react';
import { toast } from 'react-toastify';
import jsonp from 'jsonp';
import { useTranslation } from 'react-i18next';

function SignupForm() {

  const { t } = useTranslation();
  const [ email, setEmail ] = useState("");
  const [ sendingForm, setSendingForm ] = useState(false);

  const onRegister = (e) => {
    setSendingForm(true);
    e.preventDefault();
    const lang = navigator.language || navigator.userLanguage;
    const url = `https://ra1.us17.list-manage.com/subscribe/post-json?u=f71517054b00bda32112ed5f3&id=fb094304c3&EMAIL=${email}&LANGUAGE=${lang}`;
    jsonp(url, {
      param: 'c',
    }, (err, res) => {
      setSendingForm(false);
      if (res.result === 'success') {
        toast.success(t('Signup.emailSuccess'));
      } else {
        toast.error(t('Signup.emailFail'));
      }
    });
  };

  return (
    <form id="signup-form">
      <input type="email" name="email" placeholder={t('Signup.yourEmail')} value={email} onChange={e => setEmail(e.target.value)} />
      <input disabled={sendingForm} type="submit" value={t('Signup.register')} onClick={onRegister} />
    </form>
  );
};

export default SignupForm;
