import React from 'react';
import { Facebook, Instagram, Twitter } from '@icons-pack/react-simple-icons';
import { useTranslation } from 'react-i18next';

function Footer() {

  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <footer id="footer">
      <ul className="icons">
        <li>
          <a
            rel="noopener noreferrer" target="_blank"
            href="https://facebook.com/ra1.com.br"
            className="icon brands">
              <Facebook />
          </a>
        </li>
        <li>
          <a 
            rel="noopener noreferrer" target="_blank"
            href="https://instagram.com/ra1.com.br"
            className="icon brands">
              <Instagram />
          </a>
        </li>
        <li>
          <a 
            rel="noopener noreferrer" target="_blank"
            href="https://twitter.com/ra1_com_br"
            className="icon brands">
              <Twitter />
          </a>
        </li>
      </ul>
      <ul className="copyright">
        <li>&copy; {year} {t('Default.ra1')}</li>
      </ul>
    </footer>
  );
};

export default Footer;