import React from 'react';
import { useTranslation } from 'react-i18next';

function Header() {

  const { t } = useTranslation();

  return (
    <header id="header">
      <h1>{t('Default.ra1')}</h1>
      <p>
        {t('Header.text1')}&nbsp;<br />
        {t('Header.text2')}
      </p>
    </header>
  );
};

export default Header;