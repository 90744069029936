import React from 'react';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import SignupForm from 'domains/Home/SignupForm';
import { ToastContainer } from 'react-toastify';
import 'components/App/App.css';
import 'domains/Home/Home.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { withTracker } from 'withTracker';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

function App() {

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet>
          <title>{t('Default.ra1')}</title>
          <meta name="description" content={t('Meta.description')} />
        </Helmet>
        <ToastContainer hideProgressBar={true} />
        <Switch>
          <Route path={"/"}>
            <Header />
              <SignupForm />
            <Footer />
          </Route>
        </Switch>
      </HelmetProvider>
    </React.Fragment>
  );
}

export default withRouter(withTracker(App));
